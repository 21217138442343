<template v-slot:default="{ismember}">
  <div class="min-h-screen">
    <div class="2xl:px-108 xl:px-80 lg:px-40 md:px-10 px-6 py-12">
      <ArticleCardLoading
        v-if="!ARTICLE_READY"
        body-class="md:w-1/3 w-full h-5 mb-2"
        />
      <span v-else class="block text-gray-500 pt-3 sm:text-base text-subtitle-2">
        {{ GET_ARTICLE !=null ? GET_ARTICLE.category.article_category_name : 'No article match this title.'  }}
      </span>
      <h1
        class="font-semibold sm:text-headline-3 text-headline-6 leading-normal"
      >
        {{ article_title.replace(/-/g, ' ') }}
      </h1>
      <!-- <h3
        class="font-overline sm:text-headline-5 text-subtitle-1 sm:py-6 py-3 text-gray-500 sm:leading-8 leading-normal"
      >
        To be more efficient and happy, cut the waste and damaging activities
        from your life.
      </h3> -->
      <ArticleCardLoading
        v-if="!ARTICLE_READY"
        body-class="md:w-full w-full h-40 py-6"
        />
      <ArticleCardLoading
        v-if="!ARTICLE_READY"
        body-class="md:w-full w-full h-10 py-6"
        />
      <ArticleCardLoading
        v-if="!ARTICLE_READY"
        body-class="md:w-full w-full h-10 py-6"
        />
      <ArticleCardLoading
        v-if="!ARTICLE_READY"
        body-class="md:w-full w-full h-40 py-6"
        />
      <div class="flex flex-row py-6" v-if="ARTICLE_READY  && GET_ARTICLE !== null">
        <div class="rounded-full">
          <router-link
            :to="{
              name: 'route.author',
              params: { username: GET_ARTICLE.writter.fullname.replace(/ /g,'-') }
            }"
            tag="a">
            <div class="writter-svg">
              <img
                v-if="true"
                class="object-cover w-full h-full rounded-full writter-svg"
                src="@/assets/img/user-default-small.webp"
                alt=""
              />
            </div>
            <!-- <svg
              v-else
              class="w-full h-full writter-svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
                <mask
                  id="mask0"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                >
                  <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
                </mask>
                <g mask="url(#mask0)">
                  <path
                    d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
                    fill="#FAFAFA"
                  />
                </g>
              </svg> -->
          </router-link>
        </div>
        <div class="px-4"  v-if="ARTICLE_READY">
          <span class="block font-subtitle-2">
            <router-link
              :to="{
                name: 'route.author',
                params: { username: GET_ARTICLE.writter.fullname.replace(/ /g,'-') }
              }"
              tag="a">
              {{ GET_ARTICLE.writter.fullname }}
            </router-link>
          </span>
          <span class="block text-gray-500">
            {{ moment(GET_ARTICLE.published_at).format('DD/MM/YYYY') }}
          </span>
          <span class="block text-gray-5 00">
            <small>Disclaimer: Not a financial advisor, please do your own research.</small>
          </span>
        </div>
        <div class="flex-grow px-4 py-3">
          <span class="inline-block align-middle float-right" @click="changeArticleSaveState()" v-if="ismember">
            <svg
              v-if="!isBookmarked()"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z"
                fill="black"
              />
            </svg>
            <svg
              v-if="isBookmarked()"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
                fill="black"
              />
            </svg>
          <!--
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z"
                fill="black"
              />
            </svg> -->
          </span>
        </div>
      </div>
    </div>

    <div class="xl:px-40 lg:px-20 px-0 py-2" v-if="ARTICLE_READY && GET_ARTICLE !== null">
      <img
        v-if="ARTICLE_READY"
        class="object-cover w-full h-full  max-w-screen-sm mx-auto border border-gray-100"
        :src="articleImage(GET_ARTICLE.thumbnail)"
        alt=""
      />
    </div>

    <div class="2xl:px-108  xl:px-80 lg:px-40 md:px-10 px-6 lg:pt-10 pt-10 lg:pb-3 pb-3 lg:text-headline-6 lg:leading-8 leading-6" v-if="ARTICLE_READY && GET_ARTICLE !== null">
      <!-- <h4><b>Key summary</b></h4> -->
      <ul class="list-disc px-4 lg:my-5 my-2">
        <li class="py-1">{{ GET_ARTICLE.first_summary }}</li>
        <li class="py-1">{{ GET_ARTICLE.second_summary }}</li>
        <li class="py-1">{{ GET_ARTICLE.third_summary }}</li>
      </ul>
    </div>

    <!-- <div class="2xl:px-108  xl:px-80 lg:px-40 md:px-10 px-6 lg:pt-10 pt-10 lg:pb-6 pb-3 lg:text-headline-6 lg:leading-8 leading-6" v-if="ARTICLE_READY && GET_ARTICLE !== null && !ismember">
      <div class="shadow-lg px-10 py-5 rounded">
        <small>Login untuk melihat konten utama dari article ini.</small>
        <ButtonSolidBox
          class="md:w-1/8 ml-2"
          classes="py-1 px-3"
          color="primary"
          @click="showLogin()"
        >
          Sign In
        </ButtonSolidBox>
      </div>
    </div> -->

    <div class="2xl:px-108  xl:px-80 lg:px-40 md-px-10 px-6 pt-3 pb-2 lg:text-headline-6 lg:leading-8 leading-6" v-if="ARTICLE_READY && GET_ARTICLE !== null">
      <div class="pt-4" v-html="GET_ARTICLE.first_content"></div>
      <div class="pt-4" v-html="GET_ARTICLE.second_content"></div>
    </div>
  </div>
</template>
<style>
@media only screen and (min-width: 0px) {
  .ql-video {
    width: calc(100vw*0.8);
    height: calc(100vw*0.5);
  }
}
/* small */
@media only screen and (min-width: 576px) {
}
/* medium */
@media only screen and (min-width: 768px) {
  .ql-video {
    width: calc(100vw*0.75);
    height: calc(100vw*0.45);
  }
}
/* large */
@media only screen and (min-width: 992px) {
  .ql-video {
    width: calc(100vw*0.5);
    height: calc(100vw*0.25);
  }
}
/* extra large */
@media only screen and (min-width: 1200px) {
  .ql-video {
    width: calc(100vw*0.4);
    height: calc(100vw*0.2);
  }
}
/* extra extra large */
@media only screen and (min-width: 1400px) {
}
</style>
<script>
import { createNamespacedHelpers } from 'vuex'
import ArticleCardLoading from '@/components/Articles/ArticleCardLoading.vue'
// import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'

const article = createNamespacedHelpers('article')
const analytic = createNamespacedHelpers('analytic')

export default {
  components: {
    ArticleCardLoading
    // ButtonSolidBox
  },
  data () {
    return {
      article_title: '',
      // isLoggedIn: false,
      author: {
        name: 'Sean Kernan',
        avatar: require('@/assets/img/author-avatar-small-1.png')
      }
    }
  },
  props: {
    ismember: Boolean
  },
  watch: {
    ismember (val) {
      this.getArticle()
    }
  },
  computed: {
    ...article.mapGetters(['GET_ARTICLE', 'ARTICLE_READY'])
  },
  methods: {
    ...article.mapActions(['findArticleByTitle', 'toggleSaveArticle']),
    ...analytic.mapActions(['viewedArticle', 'oneMinuteViewedArticle']),
    showLogin () {
      console.log('emmiting login modal')
      this.$parent.$emit('login')
    },
    setMetaTag () {
      var meta = document.createElement('meta')
      meta.name = 'description'
      meta.content = this.getMetaDescriptionText()
      console.log(meta.content)
      document.head.appendChild(meta)
    },
    setOGMetaTag () {
      var ogTitle = document.createElement('meta')
      var ogDesc = document.createElement('meta')
      //  title
      ogTitle.setAttribute('property', 'og:title')
      ogTitle.content = this.GET_ARTICLE.article_title
      //  desc
      ogDesc.setAttribute('property', 'og:description')
      ogDesc.content = this.getMetaDescriptionText()
      document.head.appendChild(ogDesc)
      document.head.appendChild(ogTitle)
    },
    getMetaDescriptionText () {
      var descText = `${this.GET_ARTICLE.first_summary} ${this.GET_ARTICLE.second_summary} ${this.GET_ARTICLE.third_summary}`
      return descText.substring(0, `${descText}`.length > 100 ? 100 : `${descText}`.length)
    },
    isBookmarked () {
      return this.GET_ARTICLE.saved_articles.length > 0
    },
    // isLoggedIn () {
    //   return localStorage.getItem('access_token') !== null
    // },
    changeArticleSaveState () {
      this.toggleSaveArticle({ data: { article_id: this.GET_ARTICLE.id } }).then(() => {
        this.getArticle()
      })
    },
    getArticle () {
      this.findArticleByTitle({ data: { article_title: encodeURIComponent(this.article_title) } }).then(() => {
        this.setMetaTag()
        this.setOGMetaTag()
        var access = localStorage.getItem('access_token')
        if (access !== null) {
          this.viewedArticle({ data: { device_platform: navigator.platform, article_id: this.GET_ARTICLE.id } }).then(() => {
            setTimeout(() => {
              this.oneMinuteViewedArticle({ data: { device_platform: navigator.platform, article_id: this.GET_ARTICLE.id } })
            }, 60000)
          }, e => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('isLoggedOn')
            // this.isLoggedIn = false
          })
        }
      })
    }
  },
  created () {
    //  scroll to top
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    // this.isLoggedIn = localStorage.getItem('access_token') !== null
    //  end scroll to top
    this.article_title = this.$route.params.slug
    document.title = 'Algovision - ' + this.article_title.replace(/-/g, ' ')
    this.$gtag.screenview({
      app_name: 'Algovision_WebApp',
      screen_name: 'Article'
    })
    this.$gtag.pageview(this.$route.path)

    this.getArticle()
  }

}
</script>
